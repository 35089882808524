import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';
import App from './App';
// import register from './registerServiceWorker';

if (module.hot) {
    module.hot.accept();
}

ReactDOM.render(<App />, document.getElementById('root'));

// register({
//     onSuccess: () => console.log("init ran for service worker"),
//     onUpdate: (reg) => {
//         // TODO(darrelladjei): This runs automatically now, we probably
//         // want to gate it with an Alert diaglog.
//         const registrationWaiting = reg.waiting;
//         if (registrationWaiting) {
//             registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
//             registrationWaiting.addEventListener('statechange', e => {
//                 if (e.target.state === 'activated') {
//                     if (window.confirm("Updating Desktop with new version")) {
//                         window.location.reload();
//                     }
//                 }
//             });
//         }
//     },
// });
