import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import { MDBRow, MDBCol } from "mdbreact";
import Collapsible from "react-collapsible";
import { NavLink, withRouter } from "react-router-dom";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { BiGridSmall } from "react-icons/bi";
import mixpanel from "mixpanel-browser";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";

import MobileFooter from "../Layout/mobileFooter";

import MobileTopMenu from "../Layout/mobileTopMenu";

class AboutMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: "",
      clinicName: "",
      isNavbar: true,
      signUp: false,
      error: "",
      display: "none",
      buttonActive: true,
      Email: "",
      Password: "",
      isChecked: false,
      Type: "",
    };

    this.openModal = this.openModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = () => {
    this.setState({ signUp: true, isNavbar: false });
    document.body.style.overflow = "hidden";
  };

  render() {
    return (
      <>
        <div
          style={{
            width: "100vw",
            backgroundColor: "white",
            color: "black",
            overflowX: "hidden",
          }}
        >
          <MDBRow style={{ marginTop: 0, paddingBottom: 0 }}>
            <MobileTopMenu color="light" />
            <MDBRow>
              <MDBCol size="12">
                <MDBRow size="12">
                  <img
                    style={{ width: "106vw", marginTop: 40 }}
                    src={require("./team2.webp")}
                  />
                </MDBRow>
                <MDBRow style={{ paddingBottom: 20 }}>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "#175fff",
                        fontSize: 16,
                        marginTop: 90,
                        textAlign: "center",
                        opacity: 1,
                      }}
                    >
                      OUR MISSION
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSExtraBold",
                        fontSize: 28,
                        marginTop: -5,
                        lineHeight: 1.3,
                        width: "90%",
                        textAlign: "center",
                      }}
                    >
                      Protect the rights
                      <br />
                      of creators worldwide.
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 17,
                        width: "85%",
                        marginTop: 0,
                        textAlign: "center",
                        opacity: 0.7,
                      }}
                    >
                      We believe the evolution of AI advertising technology
                      introduces new challenges to creators who need to protect
                      their brand and endorsement rights. And they need someone
                      well equipped, and willing to fight for them, on their
                      side.
                    </p>
                  </MDBCol>

                  <MDBCol className="d-flex justify-content-center" size="12">
                    <a
                      href="mailto:contact@actorshield.com"
                      target="_blank"
                      className="d-flex justify-content-center"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          backgroundColor: "#175fff",
                          borderRadius: 5,
                          paddingTop: 10,
                          color: "white",
                          fontFamily: "PlusJSBold",
                          fontSize: 17,
                          width: 170,
                          height: 49,
                          marginTop: 10,
                          display: "inline-block",
                        }}
                      >
                        Get in touch
                      </div>
                    </a>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "black",
                        fontSize: 12,
                        marginTop: 5,
                        textAlign: "center",
                        opacity: 1,
                      }}
                    >
                      Have questions?
                      <a
                        href="mailto:contact@actorshield.com"
                        style={{
                          display: "inline-block",
                          color: "#175fff",
                          textDecoration: "underline",
                        }}
                      >
                        Contact us
                      </a>
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBRow>

          <MDBRow
            style={{
              paddingBottom: "5vh",
              backgroundColor: "#F5F5F5",
              color: "black",
              width: "105vw",
              paddingLeft: "8%",
              marginTop: 100,
            }}
          >
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "#175fff",
                  fontSize: 13,
                  marginTop: 70,
                  opacity: 1,
                }}
              >
                OUR STORY
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 29,
                  marginTop: -5,
                  lineHeight: 1.3,
                  width: "100%",
                }}
              >
                It all started in 2023…
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "black",
                  opacity: 0.7,
                  fontSize: 16,
                  width: "90%",
                  marginTop: 0,
                  opacity: 0.7,
                }}
              >
                Our founder started ActorShield after experiencing a growing
                issue in the industry: the unauthorized use of actors'
                likenesses, fueled by the rise of AI-generated content and
                deepfake technology. It was during a casual conversation with a
                friend in the entertainment industry that he first heard of a
                creator who found her likeness being used in an ad she never
                agreed to—and worse, the ad’s AI-generated testimonial sounded
                eerily like her own voice. This exploitation, coupled with the
                absence of clear protection, was a wake-up call.
                <br />
                <br />
                With a background in technology and a drive to protect creators,
                our founder envisioned a solution to safeguard actors and
                influencers in the digital age. ActorShield was born from the
                idea that everyone, from independent creators to well-known
                actors, deserves to control their image and be fairly
                compensated for its use.
                <br />
                <br />
                Today, ActorShield combines real-time detection technology with
                a network of top-tier law firms to monitor, identify, and take
                action on unauthorized endorsements across digital platforms.
                Our mission is simple: empower creators to regain control of
                their image and prevent deceptive, AI-generated endorsements
                from misleading audiences.
                <br />
                <br />
                Through ActorShield’s API and services, creators can ensure
                their likeness is respected, and advertisers can secure the
                proper licenses, fostering trust and authenticity in the digital
                space. With our 24/7 global detection network and a dedicated
                team of legal experts, we’re on a mission to redefine
                endorsement protection in a rapidly evolving media landscape.
                <br />
                <br />
                With the demand for digital protection growing, we’re proud to
                be leading the charge, ensuring that creators everywhere can
                feel secure in the knowledge that their image is in safe hands.
              </p>
            </MDBCol>
          </MDBRow>

          <MDBRow style={{ paddingLeft: "5%" }}>
            <div
              className="pattern2"
              style={{
                height: 400,
                width: "105vw",
                backgroundRepeat: "repeat",
              }}
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 29,
                      color: "black",
                      marginTop: 100,
                      lineHeight: 1.3,
                      width: "70%",
                      textAlign: "center",
                    }}
                  >
                    Excited to join our mission?
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 16,
                      width: "85%",
                      marginTop: 0,
                      textAlign: "center",
                      opacity: 0.7,
                    }}
                  >
                    We're assembling a team of wonderful people that love
                    solving hard problems and working together.
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <NavLink
                    to="/careers"
                    exact
                    className="d-flex justify-content-center"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: "#175fff",
                        borderRadius: 5,
                        paddingTop: 10,
                        color: "white",
                        fontFamily: "PlusJSBold",
                        fontSize: 17,
                        width: 170,
                        height: 49,
                        marginTop: 10,
                        display: "inline-block",
                      }}
                    >
                      See open roles
                    </div>
                  </NavLink>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      fontSize: 12,
                      marginTop: 5,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    Have questions?{" "}
                    <a
                      href="mailto:contact@actorshield.com"
                      style={{
                        display: "inline-block",
                        color: "#175fff",
                        textDecoration: "underline",
                      }}
                    >
                      Contact us
                    </a>
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBRow>
        </div>
        <MobileFooter
          executeScroll1={this.executeScroll1}
          executeScroll2={this.executeScroll2}
          executeScroll3={this.executeScroll3}
          executeScroll4={this.executeScroll4}
        />
      </>
    );
  }
}

export default withRouter(AboutMobile);
