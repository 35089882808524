import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import "../index.css";
import "./index.css";
import TopMenu from "../Layout/TopMenu";
import Collapsible from "react-collapsible";
import MenuDesktop from "../Layout/MenuDesktop";
import Footer from "../Layout/Footer";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink, withRouter } from "react-router-dom";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import NavBar from "../Layout/NavBar";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import VisibilitySensor from "react-visibility-sensor";
import Scroll from "react-scroll";
import FAQ from "../FAQ";

class Intro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      querySellers: [],
    };
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = () => {
    this.setState({ signUp: true, isNavbar: false });
  };

  render() {
    console.log(this.props.version);
    return (
      <>
        <MDBCol
          className="backy26"
          style={{
            width: "100vw",
            overflowX: "hidden",
            backgroundColor: "black",
            color: "white",
          }}
          size="12"
        >
          <MDBRow>
            <MenuDesktop color="dark" />
            <MDBCol size="12">
              <MDBRow style={{ paddingBottom: "5vh" }}>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "#175fff",
                      fontSize: 16,
                      marginTop: 90,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    OUR MODEL
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 49,
                      marginTop: -5,
                      lineHeight: 1.3,
                      width: "100%",
                      marginLeft: 35,
                      textAlign: "center",
                    }}
                  >
                    No Win. No Fee.
                    <br />
                    Risk-free brand protection.
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "white",
                      opacity: 0.7,
                      fontSize: 20,
                      width: "50%",
                      marginTop: 0,
                      textAlign: "center",
                      opacity: 0.7,
                    }}
                  >
                    We work hard to discover and win cases of AI generated
                    testimonials using your likeness. And we take a cut of what
                    we earn you. We never charge you upfont.
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <a
                    href="mailto:contact@actorshield.com"
                    target="_blank"
                    className="d-flex justify-content-center"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: "#175fff",
                        borderRadius: 5,
                        paddingTop: 10,
                        color: "white",
                        fontFamily: "PlusJSBold",
                        fontSize: 17,
                        width: 170,
                        height: 49,
                        marginTop: 10,
                        display: "inline-block",
                      }}
                    >
                      Get in touch
                    </div>
                  </a>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "white",
                      fontSize: 12,
                      marginTop: 5,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    Have questions?
                    <a
                      href="mailto:contact@actorshield.com"
                      style={{
                        display: "inline-block",
                        color: "#175fff",
                        textDecoration: "underline",
                      }}
                    >
                      Contact us
                    </a>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBRow
            style={{
              height: 360,
              backgroundColor: "#F5F5F5",
              color: "black",
              width: "100vw",
            }}
          >
            <MDBCol size="4">
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 29,
                  marginTop: 95,
                  lineHeight: 1.3,
                  width: "100%",
                  marginLeft: 45,
                }}
              >
                Two stage process
              </p>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "black",
                  opacity: 0.7,
                  fontSize: 17,
                  width: "90%",
                  marginTop: 0,
                  opacity: 0.7,
                  marginLeft: 45,
                }}
              >
                At first we try to negotiate a fair license fee for your
                endorsement rights, this is the quickest path for both parties.
                If this fails we pursue further action to make sure your rights
                are protected.
              </p>
            </MDBCol>
            <MDBCol size="4">
              <div
                style={{
                  marginTop: 95,
                  width: "90%",
                  height: 200,
                  marginLeft: "8%",
                  backgroundColor: "white",
                  boxShadow: "0 0 40px 0 rgb(27 6 76 / 10%)",
                }}
              >
                <MDBRow>
                  <MDBCol size="2">
                    <img
                      src={require("./startups.png")}
                      style={{
                        height: 30,
                        width: "auto",
                        marginTop: 40,
                        marginLeft: 40,
                      }}
                    />
                  </MDBCol>
                  <MDBCol size="10">
                    <p
                      style={{
                        fontFamily: "PlusJSExtraBold",
                        fontSize: 22,
                        marginTop: 42,
                        lineHeight: 1.3,
                        width: "100%",
                        marginLeft: 30,
                      }}
                    >
                      Negotiate License
                    </p>
                  </MDBCol>
                  <MDBCol size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 14,
                        width: "80%",
                        marginTop: 5,
                        opacity: 0.9,
                        marginLeft: 40,
                      }}
                    >
                      We try our best to negotiate a fair license for the
                      endorsement, we hope this process is fast for both
                      parties.
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
            <MDBCol size="4">
              <div
                style={{
                  marginTop: 95,
                  width: "90%",
                  height: 200,
                  backgroundColor: "white",
                  boxShadow: "0 0 40px 0 rgb(27 6 76 / 10%)",
                }}
              >
                <MDBRow>
                  <MDBCol size="2">
                    <img
                      src={require("./enterprise.png")}
                      style={{
                        height: 30,
                        width: "auto",
                        marginTop: 40,
                        marginLeft: 40,
                      }}
                    />
                  </MDBCol>
                  <MDBCol size="10">
                    <p
                      style={{
                        fontFamily: "PlusJSExtraBold",
                        fontSize: 22,
                        marginTop: 42,
                        lineHeight: 1.3,
                        width: "100%",
                        marginLeft: 30,
                      }}
                    >
                      Lawsuit
                    </p>
                  </MDBCol>
                  <MDBCol size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 14,
                        width: "80%",
                        marginTop: 5,
                        opacity: 0.9,
                        marginLeft: 40,
                      }}
                    >
                      If we are unable to successfuly settle the case, we work
                      with our legal partners to sue the advertiser to protect
                      your rights.
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <div
              className="pattern2"
              style={{
                height: 400,
                width: "100vw",
                backgroundRepeat: "repeat",
              }}
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 29,
                      color: "black",
                      marginTop: 100,
                      lineHeight: 1.3,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Ready to protect yourself?
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 16,
                      width: "30%",
                      marginTop: 0,
                      textAlign: "center",
                      opacity: 0.7,
                    }}
                  >
                    If you have any questions about our process or how we work
                    please get in touch with our team.
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <a
                    href="mailto:contact@actorshield.com"
                    target="_blank"
                    className="d-flex justify-content-center"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: "#175fff",
                        borderRadius: 5,
                        paddingTop: 10,
                        color: "white",
                        fontFamily: "PlusJSBold",
                        fontSize: 17,
                        width: 170,
                        height: 49,
                        marginTop: 10,
                        display: "inline-block",
                      }}
                    >
                      Get in touch
                    </div>
                  </a>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      fontSize: 12,
                      marginTop: 5,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    Have questions?{" "}
                    <a
                      href="mailto:contact@actorshield.com"
                      style={{
                        display: "inline-block",
                        color: "#175fff",
                        textDecoration: "underline",
                      }}
                    >
                      Contact us
                    </a>
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBRow>

          <MDBRow
            style={{
              width: "100vw",
              backgroundColor: "#F5F5F5",
              color: "black",
              paddingBottom: "6vh",
            }}
          >
            <FAQ />
          </MDBRow>
        </MDBCol>

        <Footer />
      </>
    );
  }
}

export default withRouter(Intro);
