import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import "../index.css";
import TopMenu from "../Layout/TopMenu";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { FiCopy, FiSearch } from "react-icons/fi";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            borderTop: "0.5px solid #E0E0E0",
            paddingBottom: "5vh",
            width: "100%",
          }}
        >
          <MDBRow style={{ width: "100%" }}>
            <MDBCol style={{ marginLeft: "10%" }} size="3">
              <p
                style={{
                  color: "black",
                  fontFamily: "PlusJSExtraBold",
                  fontWeight: "600",
                  marginTop: 90,
                  fontSize: 17,
                  marginLeft: 100,
                }}
              >
                Product
              </p>
              <NavLink to="/" exact onClick={() => window.scrollTo(0, 0)}>
                <p
                  style={{
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 14,
                    fontSize: 15,
                    marginLeft: 100,
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  Home
                </p>
              </NavLink>
              <NavLink
                to="/pricing"
                exact
                onClick={() => window.scrollTo(0, 0)}
              >
                <p
                  style={{
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 0,
                    fontSize: 15,
                    marginLeft: 100,
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  Our Model
                </p>
              </NavLink>
            </MDBCol>
            <MDBCol size="3">
              <p
                style={{
                  color: "black",
                  fontFamily: "PlusJSExtraBold",
                  fontWeight: "600",
                  marginTop: 90,
                  fontSize: 18,
                  marginLeft: 100,
                }}
              >
                Company
              </p>

              <NavLink to="/about" exact onClick={() => window.scrollTo(0, 0)}>
                <p
                  style={{
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 14,
                    fontSize: 15,
                    marginLeft: 100,
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  About
                </p>
              </NavLink>
              <NavLink
                to="/careers"
                exact
                onClick={() => window.scrollTo(0, 0)}
              >
                <p
                  style={{
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 0,
                    fontSize: 15,
                    marginLeft: 100,
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  Careers
                </p>
              </NavLink>
            </MDBCol>
            <MDBCol size="3">
              <p
                style={{
                  color: "black",
                  fontFamily: "PlusJSExtraBold",
                  fontWeight: "600",
                  marginTop: 90,
                  fontSize: 18,
                  marginLeft: 100,
                }}
              >
                Support
              </p>
              <a
                href="mailto:contact@actorshield.com"
                target="_blank"
                exact
                onClick={() => this.props.executeScroll1()}
              >
                <p
                  style={{
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: 14,
                    fontSize: 15,
                    marginLeft: 100,
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  Contact us
                </p>
              </a>
            </MDBCol>
            <MDBCol size="1"></MDBCol>
            <MDBCol style={{ marginTop: 40, marginLeft: "10%" }} size="6">
              <p
                style={{
                  display: "inline-block",
                  color: "black",
                  fontFamily: "PlusJSMedium",
                  fontWeight: "500",
                  fontSize: 15,
                  marginLeft: 100,
                  opacity: 0.8,
                }}
              >
                © 2024 ActorShield. All rights reserved.
              </p>
            </MDBCol>
          </MDBRow>
        </div>
      </>
    );
  }
}

export default Footer;
