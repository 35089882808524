import React, { Component } from "react";

import "./index.css";

import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from "react-icons/ri";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";
import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import VisibilitySensor from "react-visibility-sensor";
import { IoSearch, IoLocationSharp, IoArrowUp } from "react-icons/io5";
import Scroll from "react-scroll";

const scroll = Scroll.animateScroll;

class FAQMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      querySellers: [],
      isStopped: false,
      isPaused: false,
    };
  }

  render() {
    console.log(this.props.version);

    return (
      <>
        <MDBCol className="d-flex justify-content-center" size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 40,
              fontSize: 30,
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Questions? We have answers.
          </p>
        </MDBCol>

        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
            }}
          >
            What does ActorShield do?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            ActorShield protects actors' endorsement rights by monitoring and
            detecting unauthorized uses of their likeness in advertisements. We
            work to ensure that creators are fairly compensated and that
            advertisers have the proper licenses for using their image.
          </p>
        </MDBCol>
        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
            }}
          >
            How does ActorShield identify unauthorized use?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            Our real-time detection systems operate 24/7, scanning for
            unauthorized uses of an actor's likeness across digital platforms.
            Our technology flags potential violations instantly, allowing us to
            act quickly to enforce endorsement rights.
          </p>
        </MDBCol>
        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
            }}
          >
            What legal support does ActorShield provide?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            ActorShield partners with an extensive network of top law firms
            experienced in managing endorsement rights cases. We provide full
            legal support, from initial negotiations to civil litigation if
            necessary, all on a no-win, no-fee basis.
          </p>
        </MDBCol>
        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
            }}
          >
            Is ActorShield’s service available globally?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            Yes, ActorShield offers global coverage. Our monitoring systems and
            legal partners operate internationally, ensuring that actors' rights
            are protected regardless of where unauthorized ads are running.
          </p>
        </MDBCol>
        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
            }}
          >
            What types of endorsements does ActorShield protect?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            ActorShield focuses on protecting AI-generated endorsements,
            likeness rights, and digital personas used in advertisements without
            proper authorization. This includes endorsements simulated by AI,
            which may mislead consumers by portraying fabricated experiences.
          </p>
        </MDBCol>
        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
            }}
          >
            What happens if a violation is found?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            When a violation is identified, ActorShield immediately reaches out
            to the advertiser to address the unauthorized use. We propose a
            formal agreement for an authorized endorsement or negotiate a
            settlement to resolve the matter. If necessary, we proceed with
            civil litigation to enforce the actor’s rights.
          </p>
        </MDBCol>
        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
              width: "95%",
            }}
          >
            What is ActorShield’s fee structure?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            ActorShield operates on a contingency basis, meaning there is no
            upfront cost to the actor. We only take a fee from successful
            settlements, making our services accessible and risk-free for
            creators.
          </p>
        </MDBCol>
        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
              width: "95%",
            }}
          >
            Is it legal to pursue claims for AI-generated endorsements?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            Yes, Federal Trade Commission (FTC) regulations prohibit misleading
            AI-generated testimonials and endorsements. ActorShield enforces
            these regulations, ensuring that advertisers comply with FTC
            guidelines and other relevant laws regarding consumer protection.
          </p>
        </MDBCol>
        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
              width: "95%",
            }}
          >
            Does ActorShield support cases involving both AI and traditional
            likeness use?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            Absolutely. ActorShield handles cases involving both AI-generated
            and real likeness misuse in advertisements, providing comprehensive
            protection for actors and creators across both digital and
            traditional media.
          </p>
        </MDBCol>
        <MDBCol style={{ paddingLeft: "8%" }} size="12">
          <p
            ref={this.myRef1}
            style={{
              fontFamily: "PlusJSExtraBold",
              marginTop: 20,
              fontSize: 19,
              fontWeight: "600",
              width: "95%",
            }}
          >
            How quickly can ActorShield resolve cases?
          </p>
          <p
            style={{
              fontFamily: "PlusJSMedium",
              fontSize: 15,
              opacity: 0.7,
              fontWeight: "400",
              marginTop: -5,
              width: "95%",
            }}
          >
            ActorShield aims to resolve cases as swiftly as possible to protect
            actors’ rights and mitigate unauthorized use. Our extensive network
            and streamlined processes enable fast settlements whenever possible,
            minimizing the need for prolonged legal action.
          </p>
        </MDBCol>
      </>
    );
  }
}

export default withRouter(FAQMobile);
