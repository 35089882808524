import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import { MDBRow, MDBCol } from "mdbreact";
import Collapsible from "react-collapsible";
import { NavLink, withRouter } from "react-router-dom";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseLine } from "react-icons/ri";
import { BiGridSmall } from "react-icons/bi";
import mixpanel from "mixpanel-browser";

import "./index.css";
import { IoSearch, IoLocationSharp, IoArrowUp } from "react-icons/io5";

import { GoPrimitiveDot } from "react-icons/go";
import ReactStars from "react-stars";
import { TiTick } from "react-icons/ti";

import MobileFooter from "../Layout/mobileFooter";

import FAQMobile from "../FAQMobile";

import MobileTopMenu from "../Layout/mobileTopMenu";

class LandingMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      querySellers: [],
    };
    this.myRef1 = React.createRef();
    this.myRef2 = React.createRef();
    this.myRef3 = React.createRef();
    this.myRef4 = React.createRef();
  }

  executeScroll1 = () =>
    this.myRef1.current.scrollIntoView({ behavior: "smooth", block: "start" });
  executeScroll2 = () =>
    this.myRef2.current.scrollIntoView({ behavior: "smooth", block: "start" });
  executeScroll3 = () =>
    this.myRef3.current.scrollIntoView({ behavior: "smooth", block: "start" });
  executeScroll4 = () =>
    this.myRef4.current.scrollIntoView({ behavior: "smooth", block: "start" });

  render() {
    return (
      <>
        <div
          style={{
            width: "100vw",
            backgroundColor: "black",
            color: "white",
            overflowX: "hidden",
          }}
        >
          <MDBRow style={{ marginTop: 0, paddingBottom: 0 }}>
            <MobileTopMenu color="dark" />
            <MDBRow>
              <MDBCol size="12">
                <MDBRow>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSExtraBold",
                        fontSize: 30,
                        textAlign: "center",
                        marginTop: 90,
                        width: "90vw",
                        lineHeight: 1.3,
                      }}
                    >
                      We protect the endorsement rights of creators globally.
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        fontSize: 17,
                        marginTop: 10,
                        textAlign: "center",
                        width: "94vw",
                        opacity: 0.7,
                      }}
                    >
                      With top law firm partnerships and advanced detection
                      software, we identify and resolve FTC violations in
                      AI-generated testimonial ads across the internet.
                    </p>
                  </MDBCol>
                  <MDBCol size="12">
                    <a
                      href="mailto:contact@actorshield.com"
                      target="_blank"
                      className="d-flex justify-content-center"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          backgroundColor: "#175fff",
                          borderRadius: 5,
                          paddingTop: 10,
                          color: "white",
                          fontFamily: "PlusJSBold",
                          fontSize: 17,
                          width: 270,
                          height: 49,
                          marginTop: 20,
                          display: "inline-block",
                        }}
                      >
                        Get in touch
                      </div>
                    </a>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="12">
                <img
                  src={require("./landing.gif")}
                  style={{ height: 350, width: "auto", marginTop: 30 }}
                />
              </MDBCol>
            </MDBRow>
          </MDBRow>

          <MDBRow
            style={{
              backgroundColor: "white",
              marginTop: 0,
              overflowY: "hidden",
              paddingTop: 60,
              paddingBottom: 50,
              color: "#1c1825",
              width: "105vw",
              overflowX: "hidden",
            }}
          >
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                ref={this.myRef1}
                style={{
                  fontFamily: "PlusJSBold",
                  fontSize: 23,
                  fontWeight: "600",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Defending creators against unauthorized AI endorsements
              </p>
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 16,
                  lineHeight: 1.6,
                  textAlign: "center",
                  fontWeight: "400",
                  marginLeft: 0,
                  marginTop: 0,
                  width: "93%",
                  opacity: 0.7,
                }}
              >
                Our global network of law firms and cutting-edge detection
                software protect your rights by uncovering and resolving FTC
                violations in AI-generated ads across digital platforms.
              </p>
            </MDBCol>

            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSBold",
                  fontSize: 12,
                  fontWeight: "600",
                  marginLeft: 23,
                  marginTop: 30,
                  backgroundColor: "#f6e4eb",
                  padding: 10,
                  width: 150,
                  borderRadius: 8,
                  textAlign: "center",
                }}
              >
                CREATOR FIRST
              </p>
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 24,
                  fontWeight: "600",
                  marginLeft: 23,
                  marginTop: 9,
                  lineHeight: 1.2,
                }}
              >
                Our focus is making sure you're fairly compensated for your
                endorsements
              </p>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 16,
                  opacity: 0.8,
                  fontWeight: "400",
                  marginLeft: 23,
                  marginTop: -5,
                  width: "95%",
                }}
              >
                We work swiftly to secure fair settlements for your endorsement
                rights, ensuring advertisers have the correct licenses. If
                needed, our elite network of law firms is ready to pursue civil
                action on a no-win, no-fee basis to protect your rights.
              </p>
            </MDBCol>

            <MDBCol style={{ marginTop: 80 }} size="12">
              <p
                style={{
                  fontFamily: "PlusJSBold",
                  fontSize: 12,
                  fontWeight: "600",
                  marginLeft: 23,
                  marginTop: -20,
                  backgroundColor: "#8ed1fc",
                  padding: 10,
                  width: 180,
                  borderRadius: 8,
                  textAlign: "center",
                }}
              >
                AI DETECTOR
              </p>
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 24,
                  fontWeight: "600",
                  marginLeft: 23,
                  marginTop: 9,
                  lineHeight: 1.2,
                }}
              >
                24/7 availability with global coverage.
              </p>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 16,
                  fontWeight: "400",
                  marginLeft: 23,
                  marginTop: -5,
                  width: "95%",
                  opacity: 0.8,
                }}
              >
                Our always-on, real-time detection systems operate 24/7, 365
                days a year, ensuring continuous protection. We handle the
                complexity of managing a high-quality network of detection
                solutions, so you can trust that unauthorized uses of your
                endorsement rights are identified and addressed instantly,
                wherever they occur
              </p>
            </MDBCol>
            <MDBCol style={{ marginTop: 80 }} size="12">
              <p
                style={{
                  fontFamily: "PlusJSBold",
                  fontSize: 12,
                  fontWeight: "600",
                  marginLeft: 23,
                  marginTop: -20,
                  backgroundColor: "#00d084",
                  padding: 10,
                  width: 200,
                  borderRadius: 8,
                  textAlign: "center",
                }}
              >
                EXPERT & EXPERIENCED
              </p>
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 24,
                  fontWeight: "600",
                  marginLeft: 23,
                  marginTop: 9,
                  lineHeight: 1.2,
                }}
              >
                Extensive Legal Network with Proven Success
              </p>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 16,
                  fontWeight: "400",
                  marginLeft: 23,
                  marginTop: -5,
                  width: "95%",
                  opacity: 0.8,
                }}
              >
                We partner with a network of top-tier law firms, each with years
                of experience managing thousands of endorsement rights cases.
                Our team is dedicated to securing fair compensation and
                enforcing compliance, leveraging our extensive legal expertise
                to protect your rights effectively.
              </p>
            </MDBCol>

            <MDBCol className="d-flex justify-content-center" size="12">
              <hr
                style={{
                  width: "84%",
                  color: "black",
                  height: 1,
                  marginTop: 70,
                }}
              />
            </MDBCol>

            <FAQMobile />

            <MDBCol className="d-flex justify-content-center" size="12">
              <hr
                style={{
                  width: "84%",
                  color: "black",
                  height: 1,
                  marginTop: 70,
                }}
              />
            </MDBCol>
            <MDBCol size="12">
              <p
                ref={this.myRef1}
                style={{
                  fontFamily: "PlusJSExtraBold",
                  marginTop: 60,
                  fontSize: 30,
                  lineHeight: 1.3,
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Learn more
                <br />
                about ActorShield
              </p>
              <a
                href="mailto:contact@actorshield.com"
                target="_blank"
                className="d-flex justify-content-center"
                onClick={() => window.scrollTo(0, 0)}
              >
                <div
                  className="d-flex justify-content-center"
                  style={{
                    backgroundColor: "#175fff",
                    borderRadius: 5,
                    paddingTop: 10,
                    color: "white",
                    fontFamily: "PlusJSBold",
                    fontSize: 17,
                    width: 170,
                    height: 49,
                    marginTop: 10,
                    display: "inline-block",
                  }}
                >
                  Get in touch
                </div>
              </a>
            </MDBCol>
            <MDBCol style={{ paddingLeft: "7%" }} size="12">
              <img
                src={require("./fingers.png")}
                style={{ height: 240, width: "auto", marginTop: 50 }}
              />
            </MDBCol>
          </MDBRow>
        </div>
        <MobileFooter
          executeScroll1={this.executeScroll1}
          executeScroll2={this.executeScroll2}
          executeScroll3={this.executeScroll3}
          executeScroll4={this.executeScroll4}
        />
      </>
    );
  }
}

export default withRouter(LandingMobile);
