import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import "../index.css";
import "./index.css";
import TopMenu from "../Layout/TopMenu";
import Collapsible from "react-collapsible";
import MenuDesktop from "../Layout/MenuDesktop";
import Footer from "../Layout/Footer";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink, withRouter } from "react-router-dom";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import NavBar from "../Layout/NavBar";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import VisibilitySensor from "react-visibility-sensor";
import Scroll from "react-scroll";

class Intro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      querySellers: [],
    };
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = () => {
    this.setState({ signUp: true, isNavbar: false });
  };

  render() {
    console.log(this.props.version);
    return (
      <>
        <MDBCol
          className="backy26"
          style={{
            width: "100vw",
            overflowX: "hidden",
            backgroundColor: "white",
            color: "black",
          }}
          size="12"
        >
          <MDBRow>
            <MenuDesktop color="light" />
            <MDBCol size="12">
              <MDBRow size="12">
                <img style={{ width: "100vw" }} src={require("./team2.webp")} />
              </MDBRow>
              <MDBRow style={{ paddingBottom: "5vh" }}>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "#175fff",
                      fontSize: 16,
                      marginTop: 90,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    OUR MISSION
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 49,
                      marginTop: -5,
                      lineHeight: 1.3,
                      width: "100%",
                      marginLeft: 35,
                      textAlign: "center",
                    }}
                  >
                    Protect the rights
                    <br />
                    of creators worldwide.
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 20,
                      width: "50%",
                      marginTop: 0,
                      textAlign: "center",
                      opacity: 0.7,
                    }}
                  >
                    We believe the evolution of AI advertising technology
                    introduces new challenges to creators who need to protect
                    their brand and endorsement rights. And they need someone
                    well equipped, and willing to fight for them, on their side.
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <a
                    href="mailto:contact@actorshield.com"
                    target="_blank"
                    className="d-flex justify-content-center"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: "#175fff",
                        borderRadius: 5,
                        paddingTop: 10,
                        color: "white",
                        fontFamily: "PlusJSBold",
                        fontSize: 17,
                        width: 170,
                        height: 49,
                        marginTop: 10,
                        display: "inline-block",
                      }}
                    >
                      Get in touch
                    </div>
                  </a>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      fontSize: 12,
                      marginTop: 5,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    Have questions?
                    <a
                      href="mailto:contact@actorshield.com"
                      style={{
                        display: "inline-block",
                        color: "#175fff",
                        textDecoration: "underline",
                      }}
                    >
                      Contact us
                    </a>
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBRow
            style={{
              paddingBottom: "5vh",
              backgroundColor: "#F5F5F5",
              color: "black",
              width: "100vw",
              paddingLeft: "30%",
            }}
          >
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "#175fff",
                  fontSize: 13,
                  marginTop: 90,
                  opacity: 1,
                }}
              >
                OUR STORY
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 29,
                  marginTop: -5,
                  lineHeight: 1.3,
                  width: "100%",
                }}
              >
                It all started in 2023…
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "black",
                  opacity: 0.7,
                  fontSize: 17,
                  width: "60%",
                  marginTop: 0,
                  opacity: 0.7,
                }}
              >
                Our founder started ActorShield after experiencing a growing
                issue in the industry: the unauthorized use of actors'
                likenesses, fueled by the rise of AI-generated content and
                deepfake technology. It was during a casual conversation with a
                friend in the entertainment industry that he first heard of a
                creator who found her likeness being used in an ad she never
                agreed to—and worse, the ad’s AI-generated testimonial sounded
                eerily like her own voice. This exploitation, coupled with the
                absence of clear protection, was a wake-up call.
                <br />
                <br />
                With a background in technology and a drive to protect creators,
                our founder envisioned a solution to safeguard actors and
                influencers in the digital age. ActorShield was born from the
                idea that everyone, from independent creators to well-known
                actors, deserves to control their image and be fairly
                compensated for its use.
                <br />
                <br />
                Today, ActorShield combines real-time detection technology with
                a network of top-tier law firms to monitor, identify, and take
                action on unauthorized endorsements across digital platforms.
                Our mission is simple: empower creators to regain control of
                their image and prevent deceptive, AI-generated endorsements
                from misleading audiences.
                <br />
                <br />
                Through ActorShield’s API and services, creators can ensure
                their likeness is respected, and advertisers can secure the
                proper licenses, fostering trust and authenticity in the digital
                space. With our 24/7 global detection network and a dedicated
                team of legal experts, we’re on a mission to redefine
                endorsement protection in a rapidly evolving media landscape.
                <br />
                <br />
                With the demand for digital protection growing, we’re proud to
                be leading the charge, ensuring that creators everywhere can
                feel secure in the knowledge that their image is in safe hands.
              </p>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <div
              className="pattern2"
              style={{
                height: 400,
                width: "100vw",
                backgroundRepeat: "repeat",
              }}
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 29,
                      color: "black",
                      marginTop: 100,
                      lineHeight: 1.3,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Excited to join our mission?
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 16,
                      width: "30%",
                      marginTop: 0,
                      textAlign: "center",
                      opacity: 0.7,
                    }}
                  >
                    We're assembling a team of wonderful people that love
                    solving hard problems and working together.
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <NavLink
                    to="/careers"
                    exact
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: "#175fff",
                        borderRadius: 5,
                        paddingTop: 10,
                        color: "white",
                        fontFamily: "PlusJSBold",
                        fontSize: 17,
                        width: 170,
                        height: 49,
                        marginTop: 10,
                        display: "inline-block",
                      }}
                    >
                      See open roles
                    </div>
                  </NavLink>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      fontSize: 12,
                      marginTop: 5,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    Have questions?{" "}
                    <a
                      href="mailto:contact@actorshield.com"
                      style={{
                        display: "inline-block",
                        color: "#175fff",
                        textDecoration: "underline",
                      }}
                    >
                      Contact us
                    </a>
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBRow>
        </MDBCol>

        <Footer />
      </>
    );
  }
}

export default withRouter(Intro);
