import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import { MDBRow, MDBCol } from "mdbreact";
import Collapsible from "react-collapsible";
import { NavLink, withRouter } from "react-router-dom";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { BiGridSmall } from "react-icons/bi";
import mixpanel from "mixpanel-browser";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";

import MobileFooter from "../Layout/mobileFooter";
import Job from "./Job";

import MobileTopMenu from "../Layout/mobileTopMenu";

const jobs = [
  {
    line1:
      "We believe that the way actors protect and control their digital likenesses will drastically evolve in the next few years. We’re dedicated to empowering this transformation by building tools and services that enable creators and talent agencies to secure their endorsement rights. ActorShield powers the protections that creators and actors rely on to safeguard their digital image against unauthorized use. Founded in 2023, the company is headquartered in London.",
    line2:
      "Our ability to support new technologies, markets, and partners rests on the quality of the internal platform, abstractions, and APIs that we build. The better common building blocks we create, the faster we enable other engineering teams to build and scale each service for our clients and end-users.",
    line3:
      "We are hiring a Software Engineer to build out our abstractions team, which provides common abstractions and tools for AI detection and case management. For example, the creation of a single external API route may require permissions, real-time alerts, and notifications across multiple data models for tracking unauthorized likeness use.",
    line4:
      "As an engineer, you’ll help build, scale, and design the next generation of ActorShield's protective infrastructure. Our engineering culture is IC-driven—we favor bottom-up ideation and the empowerment of our incredibly talented team.",
    a: "Building products with real impact: your work will protect the rights of millions of actors and content creators globally.",
    b: "Scaling ActorShield’s core data models and real-time detection systems.",
    c: "Building product engineering teams and designing backend abstractions, while making pragmatic tradeoffs.",
    d: "5+ years of experience with backend work/database scaling/API Design.",
    e: "Experience with APIs, scalability, and performance.",
    f: "Empathy and enthusiasm for understanding other teams’ challenges.",
  },
  {
    line1:
      "We believe that the way actors protect and control their digital likenesses will drastically evolve in the next few years. We’re dedicated to empowering this transformation by building tools and services that enable creators and talent agencies to secure their endorsement rights. ActorShield powers the protections that creators and actors rely on to safeguard their digital image against unauthorized use. Founded in 2023, the company is headquartered in London.",
    line2:
      "Risk Engineering is a highly cross-functional team dedicated to building the defenses, systems, and workflows needed to mitigate AI-driven risks, protect actors, and ensure ActorShield’s clients are compliant with regulatory standards.",
    line3:
      "In this role, you will be responsible for building the rules and machine learning models that detect high-risk unauthorized likeness use on the platform, as well as the mitigation tools we need to quickly respond to violations.",
    line4:
      "You’ll build the core ActorShield team to define and implement new defenses as our business evolves and new challenges arise.",
    a: "Building products with real impact: your work will protect the rights of millions of actors and creators globally.",
    b: "Scaling ActorShield’s core data models and real-time compliance systems.",
    c: "Building engineering teams and designing the right backend abstractions while making pragmatic tradeoffs.",
    d: "4+ years of industry experience building trust and safety solutions or scalable backend systems.",
    e: "Understanding and prioritizing risk vectors quantitatively.",
    f: "Successful projects across the engineering stack that tackle fraud, abuse, spam, or trust and safety at scale.",
  },
  {
    line1:
      "We believe that the way actors protect and control their digital likenesses will drastically evolve in the next few years. We’re dedicated to empowering this transformation by building tools and services that enable creators and talent agencies to secure their endorsement rights. ActorShield powers the protections that creators and actors rely on to safeguard their digital image against unauthorized use. Founded in 2023, the company is headquartered in London.",
    line2:
      "The Infrastructure Self-Serve team is tasked with building abstractions on top of ActorShield’s detection platform, so application developers at ActorShield can access a highly reliable platform without having to configure its low-level components.",
    line3:
      "You'll build the infrastructure self-serve team from the ground up.",
    line4:
      "On this team, you will be responsible for identifying and advocating for solutions that best meet the needs of the company.",
    a: "Using software-driven solutions to solve Infrastructure Engineering or DevOps challenges.",
    b: "Collaborating with various engineering teams to achieve common goals.",
    c: "Contributing across the entire stack and across teams to ensure the success of ActorShield and its clients.",
    d: "Experience with Kubernetes (or a similar container orchestration system).",
    e: "Experience with Terraform.",
    f: "Experience with cloud infrastructure, AWS preferred.",
  },
  {
    line1:
      "We believe that the way actors protect and control their digital likenesses will drastically evolve in the next few years. We’re dedicated to empowering this transformation by building tools and services that enable creators and talent agencies to secure their endorsement rights. ActorShield powers the protections that creators and actors rely on to safeguard their digital image against unauthorized use. Founded in 2023, the company is headquartered in London.",
    line2:
      "ActorShield’s Legal Team works at the intersection of technology, law, and digital rights protection. Our work helps ActorShield build protective and innovative products, make better decisions, understand and manage risks, and navigate legal challenges creatively.",
    line3:
      "We’re looking for a Product Counsel to join our Legal Team! In this role, you’ll work closely with Product, Engineering, Marketing, and other teams to understand and assess new services, from early development stages through launch, and help scale existing services. You’ll operate as an embedded member of the product team and be a trusted advisor on complex legal, regulatory, and privacy issues.",
    line4:
      "The ideal candidate will be a highly collaborative, inquisitive, and solutions-oriented lawyer with experience advising on product development in a fast-moving tech company. Experience with digital rights management or advertising compliance is helpful, but not required.",
    a: "Providing pragmatic, creative, and strategic advice to product teams on legal issues, risks, and opportunities, including new service development and launch.",
    b: "Collaborating with diverse teams at ActorShield on complex legal questions related to digital rights and regulatory compliance.",
    c: "Building strong relationships with product and business colleagues as a trusted advisor to help them ship great products.",
    d: "J.D. degree and membership in at least one U.S. state bar, with 4+ years of relevant experience advising on product development at a tech company.",
    e: "Creative thinker with comfort in interpreting and applying the law in evolving contexts.",
    f: "Highly collaborative and dedicated to developing strong relationships with key business partners.",
  },
  {
    line1:
      "We believe that the way actors protect and control their digital likenesses will drastically evolve in the next few years. We’re dedicated to empowering this transformation by building tools and services that enable creators and talent agencies to secure their endorsement rights. ActorShield powers the protections that creators and actors rely on to safeguard their digital image against unauthorized use. Founded in 2023, the company is headquartered in London.",
    line2:
      "The Internal Platform at ActorShield enables our business to scale and accelerates service development by allowing our engineering teams to focus on building the future of digital rights management. It’s a deeply technical team responsible for the foundational systems that reduce infrastructure burdens, including cloud services, developer tooling, data and machine learning platforms, and shared components across all services.",
    line3:
      "We are looking for an experienced product leader to partner with our Head of Infrastructure to drive vision and strategy for our Internal Platform. This leader will build a high-performing PM team that deeply understands our customers’ needs, prioritizes key opportunities, and measures success effectively.",
    line4:
      "Product Management for Internal Platform is a new team, which means you’ll help shape the vision, culture, and practices for this vital function at ActorShield. If you’re looking for a deeply technical and dynamic role that will have an outsized impact on the future of digital rights protection, we’d love to talk to you!",
    a: "Partnering with our Head of Infrastructure to drive product vision and strategy across infrastructure, developer tools, and platform offerings at ActorShield.",
    b: "Deeply understanding the needs of product development teams at ActorShield.",
    c: "Fostering an environment that is customer-focused, data-driven, and experimentation-based.",
    d: "A deep understanding of platforms and creating building blocks for developers.",
    e: "Experience building, coaching, and retaining high-performing, diverse PM teams.",
    f: "Demonstrable understanding of cloud infrastructure, developer tooling, and/or data/ML platforms at scale.",
  },
];
class HiringMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: "",
      clinicName: "",
      isNavbar: true,
      signUp: false,
      error: "",
      display: "none",
      buttonActive: true,
      Email: "",
      Password: "",
      isChecked: false,
      Type: "",
    };

    this.openModal = this.openModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = () => {
    this.setState({ signUp: true, isNavbar: false });
    document.body.style.overflow = "hidden";
  };

  componentDidMount() {}

  render() {
    return (
      <>
        <div
          style={{
            width: "100vw",
            backgroundColor: "white",
            color: "black",
            overflowX: "hidden",
          }}
        >
          <MDBRow style={{ marginTop: 0, paddingBottom: 0 }}>
            <MobileTopMenu color="light" />
            <MDBRow>
              <MDBCol size="12">
                <MDBRow style={{ paddingBottom: 20 }}>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "#175fff",
                        fontSize: 16,
                        marginTop: 90,
                        textAlign: "center",
                        opacity: 1,
                      }}
                    >
                      JOBS AT ACTORSHIELD
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSExtraBold",
                        fontSize: 28,
                        marginTop: -5,
                        lineHeight: 1.3,
                        width: "90%",
                        textAlign: "center",
                      }}
                    >
                      Let's build the future
                      <br />
                      of creator protection together
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 17,
                        width: "85%",
                        marginTop: 0,
                        textAlign: "center",
                        opacity: 0.7,
                      }}
                    >
                      We're assembling a team of wonderful people that love
                      solving hard problems and working together.
                    </p>
                  </MDBCol>

                  <MDBCol className="d-flex justify-content-center" size="12">
                    <NavLink
                      exact
                      to="/about"
                      className="d-flex justify-content-center"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          backgroundColor: "#175fff",
                          borderRadius: 5,
                          paddingTop: 10,
                          color: "white",
                          fontFamily: "PlusJSBold",
                          fontSize: 17,
                          width: 170,
                          height: 49,
                          marginTop: 10,
                          display: "inline-block",
                        }}
                      >
                        Our mission
                      </div>
                    </NavLink>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "black",
                        fontSize: 12,
                        marginTop: 5,
                        textAlign: "center",
                        opacity: 1,
                      }}
                    >
                      Have questions?
                      <a
                        href="mailto:contact@actorshield.com"
                        style={{
                          display: "inline-block",
                          color: "#175fff",
                          textDecoration: "underline",
                        }}
                      >
                        Contact us
                      </a>
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBRow>
          <MDBRow
            style={{
              paddingBottom: "12vh",
              backgroundColor: "#F5F5F5",
              color: "black",
              width: "105vw",
              paddingLeft: "8%",
              marginTop: 50,
            }}
          >
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 29,
                  marginTop: 65,
                  lineHeight: 1.3,
                  width: "100%",
                }}
              >
                Engineering
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "black",
                  opacity: 0.7,
                  fontSize: 16,
                  width: "90%",
                  marginTop: 0,
                  opacity: 0.7,
                }}
              >
                We build advanced detection software that analyzes millions of
                ads on a daily basis in real-time to discover infringements.
              </p>
            </MDBCol>

            <Job
              jobs={jobs[0]}
              category="Engineering"
              title="Experienced Software Engineer - Core Platform"
            />
            <Job
              jobs={jobs[1]}
              category="Engineering"
              title="Experienced Software Engineer - Risk Engineer"
            />
            <Job
              jobs={jobs[2]}
              category="Engineering"
              title="Infrastructure Engineer - Tooling & Automation"
            />

            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 29,
                  marginTop: 65,
                  lineHeight: 1.3,
                  width: "100%",
                }}
              >
                Legal
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "black",
                  opacity: 0.7,
                  fontSize: 16,
                  width: "90%",
                  marginTop: 0,
                  opacity: 0.7,
                }}
              >
                We provide the best internal resources to make sure creator
                contracts are compliant and fair.
              </p>
            </MDBCol>

            <Job jobs={jobs[3]} category="Legal" title="Product Counsel" />

            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 29,
                  marginTop: 65,
                  lineHeight: 1.3,
                  width: "100%",
                }}
              >
                Product
              </p>
            </MDBCol>
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "black",
                  opacity: 0.7,
                  fontSize: 16,
                  width: "90%",
                  marginTop: 0,
                  opacity: 0.7,
                }}
              >
                We evaluate how to ensure payouts and onboarding for creators
                are simple and fast.
              </p>
            </MDBCol>

            <Job
              jobs={jobs[4]}
              category="Product"
              title="Head of Product - Internal Platform"
            />
          </MDBRow>

          <MDBRow style={{ paddingLeft: "5%" }}>
            <div
              className="pattern2"
              style={{
                height: 400,
                width: "105vw",
                backgroundRepeat: "repeat",
              }}
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 29,
                      color: "black",
                      marginTop: 100,
                      lineHeight: 1.3,
                      width: "70%",
                      textAlign: "center",
                    }}
                  >
                    Excited to join our mission?
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 16,
                      width: "85%",
                      marginTop: 0,
                      textAlign: "center",
                      opacity: 0.7,
                    }}
                  >
                    We're assembling a team of wonderful people that love
                    solving hard problems and working together.
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <NavLink
                    exact
                    to="/about"
                    className="d-flex justify-content-center"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: "#175fff",
                        borderRadius: 5,
                        paddingTop: 10,
                        color: "white",
                        fontFamily: "PlusJSBold",
                        fontSize: 17,
                        width: 170,
                        height: 49,
                        marginTop: 10,
                        display: "inline-block",
                      }}
                    >
                      Our mission
                    </div>
                  </NavLink>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      fontSize: 12,
                      marginTop: 5,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    Have questions?{" "}
                    <a
                      href="mailto:contact@actorshield.com"
                      style={{
                        display: "inline-block",
                        color: "#175fff",
                        textDecoration: "underline",
                      }}
                    >
                      Contact us
                    </a>
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBRow>
        </div>
        <MobileFooter
          executeScroll1={this.executeScroll1}
          executeScroll2={this.executeScroll2}
          executeScroll3={this.executeScroll3}
          executeScroll4={this.executeScroll4}
        />
      </>
    );
  }
}

export default withRouter(HiringMobile);
