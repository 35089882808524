import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import "../index.css";
import "./index.css";
import TopMenu from "../Layout/TopMenu";
import Collapsible from "react-collapsible";
import MenuDesktop from "../Layout/MenuDesktop";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink, withRouter } from "react-router-dom";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import NavBar from "../Layout/NavBar";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import VisibilitySensor from "react-visibility-sensor";
import Scroll from "react-scroll";
import Footer from "../Layout/Footer";

class Job extends Component {
  constructor(props) {
    super(props);

    this.state = {
      querySellers: [],
    };
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = () => {
    this.setState({ signUp: true, isNavbar: false });
  };

  render() {
    console.log(this.props.version);
    return (
      <>
        <MDBCol
          style={{ borderBottom: "1px solid #DCDCDC", paddingBottom: 8 }}
          size="6"
        >
          <p
            style={{
              fontFamily: "PlusJSRegular",
              color: "black",
              opacity: 0.7,
              fontSize: 16,
              width: "100%",
              marginTop: 40,
              opacity: 0.7,
            }}
          >
            {this.props.title}
          </p>
        </MDBCol>
        <MDBCol
          style={{ borderBottom: "1px solid #DCDCDC", paddingBottom: 8 }}
          size="3"
        >
          <p
            style={{
              fontFamily: "PlusJSRegular",
              color: "black",
              opacity: 0.7,
              fontSize: 16,
              width: "60%",
              marginTop: 40,
              opacity: 0.7,
            }}
          >
            <img
              src={require("./remote.png")}
              style={{
                height: 20,
                width: "auto",
                marginRight: 10,
                marginTop: -3,
              }}
            />{" "}
            Remote
          </p>
        </MDBCol>
        <MDBCol
          style={{
            borderBottom: "1px solid #DCDCDC	",
            paddingBottom: 8,
            width: 100,
          }}
          size="3"
        >
          <NavLink
            to={`/careers/job?title=${this.props.title}&category=${this.props.category}&line1=${this.props.jobs.line1}&line2=${this.props.jobs.line2}&line3=${this.props.jobs.line3}&line4=${this.props.jobs.line4}&a=${this.props.jobs.a}&b=${this.props.jobs.b}&c=${this.props.jobs.c}&d=${this.props.jobs.d}&e=${this.props.jobs.e}&f=${this.props.jobs.f}`}
            exact
            onClick={() => window.scrollTo(0, 0)}
          >
            <RiArrowRightSLine
              style={{
                fontSize: 27,
                marginTop: 35,
                color: "black",
                opacity: 0.7,
              }}
            />
          </NavLink>
        </MDBCol>
      </>
    );
  }
}

export default withRouter(Job);
