import React, { useState, useRef, useEffect, Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";

import { NavLink } from "react-router-dom";
import { RiExternalLinkLine, RiSearchLine, RiCloseLine } from "react-icons/ri";
import { MdSearch } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import disableScroll from "disable-scroll";
import { BiSearch } from "react-icons/bi";

import "../index.css";

class MobileTopMenu extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      status: null,
      navVisible: false,
      accountVisible: false,
      initials: "",
      menuOpen: false,
    };
    this.hideNav = this.hideNav.bind(this);
  }

  hideNav = () => {
    document.body.style.overflow = "unset";
    this.setState({ navVisible: false });
  };

  executeScroll = () => this.myRef.current.scrollIntoView();

  componentDidMount() {
    this.executeScroll();
  }

  render() {
    return (
      <>
        <div ref={this.myRef} style={{ position: "absolute", top: 0 }}></div>
        <div style={{ zIndex: 100000 }}>
          <div
            onClick={() =>
              this.setState({ accountVisible: !this.state.accountVisible })
            }
            style={{
              fontSize: "19px",
              fontFamily: "GellixBold",
              zIndex: 2020,
              left: 120,
              top: 0,
              color: "black",
              width: "100vw",
              borderRadius: "0px",
              backgroundColor: "#F0F0F0",
              paddingTop: 0,
              height: 0,
              paddingBottom: 0,
              boxShadow: "0 2px 4px 0 rgb(0 0 0 / 5%)",
            }}
            className="d-flex justify-content-center hoverBorder"
          ></div>

          <div
            onClick={() =>
              this.setState({ accountVisible: !this.state.accountVisible })
            }
            style={{
              fontSize: "19px",
              fontFamily: "GellixBold",
              zIndex: 2020,
              left: 120,
              top: 9,

              color: "black",
              width: "100vw",
              paddingTop: 15,
              paddingBottom: 0,
              height: 60,
            }}
            className={this.props.class}
          >
            <NavLink to="/" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  fontFamily: "PlusJSBold",
                  color: this.props.color === "dark" ? "white" : "black",
                  fontSize: 23,
                  fontWeight: "900",
                  marginTop: 8,
                  display: "inline-block",
                  marginLeft: 39,
                }}
              >
                <img
                  style={{
                    height: 35,
                    width: "auto",
                    marginRight: 5,
                    marginTop: -5,
                    transform: "rotate(45deg)",
                  }}
                  src={require("./logo.png")}
                />
                ActorShield
              </p>
            </NavLink>

            <div>
              {/* <IoPersonSharp style={{ display: "inline-block", color: "white", fontWeight: "bold", fontFamily: "Righteous", marginRight: 10, fontSize: 30, marginTop: 6 }} /> */}
              {this.state.menuOpen ? (
                <RiCloseLine
                  onClick={() => {
                    document.body.style.overflow = "scroll";
                    this.setState({ menuOpen: false });
                  }}
                  style={{
                    position: "absolute",
                    right: "3%",
                    top: 30,
                    opacity: 1,
                    fontSize: 20,
                    color: this.props.color === "dark" ? "white" : "black",
                  }}
                />
              ) : (
                <GiHamburgerMenu
                  onClick={() => {
                    this.setState({ menuOpen: true });
                    document.body.style.overflow = "hidden";
                  }}
                  style={{
                    color: this.props.color === "dark" ? "white" : "black",
                    fontWeight: "bold",
                    position: "absolute",
                    right: "3%",
                    fontFamily: "Righteous",
                    fontSize: 17,
                    top: 33,
                  }}
                />
              )}
            </div>

            {/* <BiSearch onClick={() => this.props.openModal ? this.props.openModal() : null} style={{ color: "white", transform: "rotate(90deg)", fontWeight: "bold", fontFamily: "Righteous", position: "absolute", right: "8%", fontSize: 23, top: 22 }} /> */}
          </div>
        </div>

        {this.state.menuOpen ? (
          <div
            style={{
              position: "absolute",
              height: "100vh",
              width: "105vw",
              zIndex: 120000,
              top: 70,
              backgroundColor: this.props.color === "dark" ? "black" : "white",
              overflowX: "hidden",
              overflowY: "hidden",
            }}
          >
            <MDBRow style={{ marginTop: 30 }}>
              <MDBCol size="12">
                <NavLink
                  to="/pricing"
                  exact
                  onClick={() => {
                    document.body.style.overflow = "scroll";
                    this.setState({ menuOpen: false });
                  }}
                >
                  <p
                    className="d-inline"
                    style={{
                      color: this.props.color === "dark" ? "white" : "black",
                      marginLeft: 50,
                      fontWeight: "500",
                      top: 17,
                      fontFamily: "PlusJSBold",
                      fontSize: 19,
                      opacity: 1,
                      lineHeight: 1.2,
                    }}
                  >
                    Our Model
                  </p>
                </NavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: 20 }}>
              <MDBCol size="12">
                <NavLink
                  to="/about"
                  exact
                  onClick={() => {
                    document.body.style.overflow = "scroll";
                    this.setState({ menuOpen: false });
                  }}
                >
                  <p
                    className="d-inline"
                    style={{
                      color: this.props.color === "dark" ? "white" : "black",
                      marginLeft: 50,
                      fontWeight: "500",
                      top: 17,
                      fontFamily: "PlusJSBold",
                      fontSize: 19,
                      opacity: 1,
                      lineHeight: 1.2,
                    }}
                  >
                    About us
                  </p>
                </NavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: 20 }}>
              <MDBCol size="12">
                <NavLink
                  to="/careers"
                  exact
                  onClick={() => {
                    document.body.style.overflow = "scroll";
                    this.setState({ menuOpen: false });
                  }}
                >
                  <p
                    className="d-inline"
                    style={{
                      color: this.props.color === "dark" ? "white" : "black",
                      marginLeft: 50,
                      fontWeight: "500",
                      top: 17,
                      fontFamily: "PlusJSBold",
                      fontSize: 19,
                      opacity: 1,
                      lineHeight: 1.2,
                    }}
                  >
                    We're hiring!
                  </p>
                </NavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: 20 }}>
              <MDBCol size="12">
                <NavLink
                  to="/sign-in"
                  exact
                  className="d-flex justify-content-center"
                  style={{
                    padding: 10,
                    border:
                      this.props.color === "dark"
                        ? "1.6px solid white"
                        : "1.6px solid black",
                    width: 180,
                    marginLeft: 50,
                    height: 45,
                  }}
                >
                  <p
                    className="d-inline"
                    style={{
                      color: this.props.color === "dark" ? "white" : "black",
                      fontWeight: "500",
                      top: 17.5,
                      fontFamily: "PlusJSBold",
                      fontSize: 16,
                      opacity: 1,
                      lineHeight: 1.2,
                    }}
                  >
                    Get in touch
                  </p>
                </NavLink>
              </MDBCol>
            </MDBRow>
          </div>
        ) : null}
      </>
    );
  }
}

export default MobileTopMenu;
