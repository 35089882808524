import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import "../index.css";
import "./index.css";
import TopMenu from "../Layout/TopMenu";
import MenuDesktop from "../Layout/MenuDesktop";
import { MDBRow, MDBCol } from "mdbreact";
import { NavLink, withRouter } from "react-router-dom";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from "react-icons/ri";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import VisibilitySensor from "react-visibility-sensor";
import { IoSearch, IoLocationSharp, IoArrowUp } from "react-icons/io5";
import Scroll from "react-scroll";
import Footer from "../Layout/Footer";
import FAQ from "../FAQ";

const scroll = Scroll.animateScroll;

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

let x1 = randomIntFromInterval(10, 200);

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      querySellers: [],
      isStopped: false,
      isPaused: false,
    };

    this.myRef1 = React.createRef();
    this.myRef2 = React.createRef();
    this.myRef3 = React.createRef();
    this.myRef4 = React.createRef();
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = () => {
    this.setState({ signUp: true, isNavbar: false });
  };

  executeScroll1 = () =>
    this.myRef1.current.scrollIntoView({ behavior: "smooth", block: "start" });
  executeScroll2 = () =>
    this.myRef2.current.scrollIntoView({ behavior: "smooth", block: "start" });
  executeScroll3 = () =>
    this.myRef3.current.scrollIntoView({ behavior: "smooth", block: "start" });
  executeScroll4 = () =>
    this.myRef4.current.scrollIntoView({ behavior: "smooth", block: "start" });

  render() {
    console.log(this.props.version);

    return (
      <>
        <MDBCol
          style={{
            width: "100vw",
            overflowX: "hidden",
            backgroundColor: "black",
            color: "white",
            paddingBottom: 0,
          }}
          size="12"
        >
          <MDBRow style={{ marginTop: 0, paddingBottom: 0 }}>
            <MenuDesktop color="dark" />
            <MDBRow>
              <MDBCol size="6">
                <MDBRow style={{ paddingLeft: "10%" }}>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSExtraBold",
                        fontSize: 40,
                        marginTop: 60,
                        lineHeight: 1.1,
                        width: "100%",
                        marginLeft: 35,
                      }}
                    >
                      We protect the endorsement rights of creators globally.
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        fontSize: 19,
                        marginTop: 10,
                        marginLeft: 35,
                        opacity: 0.7,
                      }}
                    >
                      With top law firm partnerships and advanced detection
                      software, we identify and resolve FTC violations in
                      AI-generated testimonial ads across the internet.
                    </p>
                  </MDBCol>
                  <MDBCol size="12">
                    <a
                      href="mailto:contact@actorshield.com"
                      target="_blank"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          backgroundColor: "#175fff",
                          borderRadius: 5,
                          paddingTop: 10,
                          color: "white",
                          fontFamily: "PlusJSBold",
                          fontSize: 17,
                          width: 270,
                          height: 49,
                          marginLeft: 35,
                          marginTop: 20,
                          display: "inline-block",
                        }}
                      >
                        Get in touch
                      </div>
                    </a>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol className="d-flex justify-content-center" size="6">
                <img
                  src={require("./landing.gif")}
                  style={{ height: 450, width: "auto", marginTop: 30 }}
                />
              </MDBCol>
            </MDBRow>
          </MDBRow>

          <MDBRow
            style={{
              backgroundColor: "white",
              marginTop: 0,
              overflowY: "hidden",
              paddingTop: 60,
              paddingBottom: 50,
              color: "#1c1825",
            }}
          >
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                ref={this.myRef1}
                style={{
                  fontFamily: "PlusJSBold",
                  fontSize: 30,
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Defending creators against
                <br />
                unauthorized AI endorsements
              </p>
            </MDBCol>
            <MDBCol className="d-flex justify-content-center" size="12">
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 18,
                  lineHeight: 1.6,
                  textAlign: "center",
                  fontWeight: "400",
                  marginLeft: 0,
                  marginTop: 0,
                  width: "50%",
                  opacity: 0.7,
                }}
              >
                Our global network of law firms and cutting-edge detection
                software protect your rights by uncovering and resolving FTC
                violations in AI-generated ads across digital platforms.
              </p>
            </MDBCol>

            <MDBCol size="6">
              <p
                style={{
                  fontFamily: "PlusJSBold",
                  fontSize: 14,
                  fontWeight: "600",
                  marginLeft: 88,
                  marginTop: 80,
                  backgroundColor: "#f6e4eb",
                  padding: 10,
                  width: 150,
                  borderRadius: 8,
                  textAlign: "center",
                }}
              >
                CREATOR FIRST
              </p>
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 40,
                  fontWeight: "600",
                  marginLeft: 88,
                  marginTop: 9,
                  lineHeight: 1.2,
                }}
              >
                Our focus is making sure you're fairly compensated for your
                endorsements
              </p>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 18,
                  opacity: 0.8,
                  fontWeight: "400",
                  marginLeft: 88,
                  marginTop: -5,
                  width: "70%",
                }}
              >
                We work swiftly to secure fair settlements for your endorsement
                rights, ensuring advertisers have the correct licenses. If
                needed, our elite network of law firms is ready to pursue civil
                action on a no-win, no-fee basis to protect your rights.
              </p>
            </MDBCol>

            <MDBCol style={{ marginTop: 80 }} size="6">
              <p
                style={{
                  fontFamily: "PlusJSBold",
                  fontSize: 14,
                  fontWeight: "600",
                  marginLeft: 70,
                  marginTop: 0,
                  backgroundColor: "#8ed1fc",
                  padding: 10,
                  width: 200,
                  borderRadius: 8,
                  textAlign: "center",
                }}
              >
                AI DETECTOR
              </p>
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 40,
                  fontWeight: "600",
                  marginLeft: 70,
                  marginTop: 9,
                  lineHeight: 1.2,
                }}
              >
                24/7 availability with global coverage.
              </p>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 18,
                  fontWeight: "400",
                  marginLeft: 70,
                  marginTop: -5,
                  width: "70%",
                  opacity: 0.8,
                }}
              >
                Our always-on, real-time detection systems operate 24/7, 365
                days a year, ensuring continuous protection. We handle the
                complexity of managing a high-quality network of detection
                solutions, so you can trust that unauthorized uses of your
                endorsement rights are identified and addressed instantly,
                wherever they occur
              </p>
            </MDBCol>
            <MDBCol style={{ marginTop: 150 }} size="12">
              <p
                style={{
                  fontFamily: "PlusJSBold",
                  fontSize: 14,
                  fontWeight: "600",
                  marginLeft: 88,
                  marginTop: 0,
                  backgroundColor: "#00d084",
                  padding: 10,
                  width: 200,
                  borderRadius: 8,
                  textAlign: "center",
                }}
              >
                EXPERT & EXPERIENCED
              </p>
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 40,
                  fontWeight: "600",
                  marginLeft: 88,
                  marginTop: 9,
                  lineHeight: 1.2,
                }}
              >
                Extensive Legal Network with Proven Success
              </p>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 18,
                  fontWeight: "400",
                  marginLeft: 88,
                  marginTop: -5,
                  width: "70%",
                  opacity: 0.8,
                }}
              >
                We partner with a network of top-tier law firms, each with years
                of experience managing thousands of endorsement rights cases.
                Our team is dedicated to securing fair compensation and
                enforcing compliance, leveraging our extensive legal expertise
                to protect your rights effectively.
              </p>
            </MDBCol>

            <MDBCol className="d-flex justify-content-center" size="12">
              <hr
                style={{
                  width: "84%",
                  color: "black",
                  height: 1,
                  marginTop: 70,
                }}
              />
            </MDBCol>

            <FAQ />

            <MDBCol className="d-flex justify-content-center" size="12">
              <hr
                style={{
                  width: "84%",
                  color: "black",
                  height: 1,
                  marginTop: 70,
                }}
              />
            </MDBCol>
            <MDBCol style={{ paddingLeft: "12%" }} size="6">
              <p
                ref={this.myRef1}
                style={{
                  fontFamily: "PlusJSExtraBold",
                  marginTop: 60,
                  fontSize: 40,
                  lineHeight: 1.1,
                  fontWeight: "600",
                }}
              >
                Learn more about ActorShield
              </p>
              <a
                href="mailto:contact@actorshield.com"
                target="_blank"
                onClick={() => window.scrollTo(0, 0)}
              >
                <div
                  className="d-flex justify-content-center"
                  style={{
                    backgroundColor: "#175fff",
                    borderRadius: 5,
                    paddingTop: 10,
                    color: "white",
                    fontFamily: "PlusJSBold",
                    fontSize: 17,
                    width: 170,
                    height: 49,
                    marginTop: 40,
                    display: "inline-block",
                  }}
                >
                  Get in touch
                </div>
              </a>
            </MDBCol>
            <MDBCol style={{ paddingLeft: "7%" }} size="6">
              <img
                src={require("./fingers.png")}
                style={{ height: 240, width: "auto", marginTop: 50 }}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <Footer
          executeScroll1={this.executeScroll1}
          executeScroll2={this.executeScroll2}
          executeScroll3={this.executeScroll3}
          executeScroll4={this.executeScroll4}
        />
      </>
    );
  }
}

export default withRouter(Landing);
