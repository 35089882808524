import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import "../index.css";
import TopMenu from "../Layout/TopMenu";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { FiCopy, FiSearch } from "react-icons/fi";

class MobileFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            borderTop: "0.5px solid #E0E0E0",
            paddingBottom: 20,
            width: "100%",
            marginTop: 0,
            paddingTop: 60,
          }}
        >
          <MDBRow style={{ width: "100%" }}>
            <MDBCol style={{ paddingLeft: "10%" }} size="6">
              <p
                style={{
                  color: "black",
                  fontFamily: "PlusJSExtraBold",
                  fontWeight: "600",
                  marginTop: 0,
                  fontSize: 16,
                  marginLeft: "5.5%",
                  color: "black",
                }}
              >
                Product
              </p>
              <NavLink to="/" exact>
                <p
                  style={{
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: -8,
                    fontSize: 15,
                    marginLeft: "5.5%",
                    color: "black",
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  Home
                </p>
              </NavLink>
              <NavLink to="/pricing" exact>
                <p
                  style={{
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: -7,
                    fontSize: 15,
                    marginLeft: "5.5%",
                    color: "black",
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  Our model
                </p>
              </NavLink>
            </MDBCol>
            <MDBCol style={{ paddingLeft: "10%" }} size="6">
              <p
                style={{
                  color: "black",
                  fontFamily: "PlusJSExtraBold",
                  fontWeight: "600",
                  marginTop: 0,
                  fontSize: 16,
                  marginLeft: "5.5%",
                  color: "black",
                }}
              >
                Company
              </p>
              <NavLink to="/about" exact>
                <p
                  style={{
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: -8,
                    fontSize: 15,
                    marginLeft: "5.5%",
                    color: "black",
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  About
                </p>
              </NavLink>
              <NavLink to="/careers" exact>
                <p
                  style={{
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: -7,
                    fontSize: 15,
                    marginLeft: "5.5%",
                    color: "black",
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  Careers
                </p>
              </NavLink>
            </MDBCol>
            <MDBCol style={{ paddingLeft: "10%" }} size="6">
              <p
                style={{
                  color: "black",
                  fontFamily: "PlusJSExtraBold",
                  fontWeight: "600",
                  marginTop: 30,
                  fontSize: 16,
                  marginLeft: "5.5%",
                  color: "black",
                }}
              >
                Support
              </p>
              <a href="mailto:contact@actorshield.com" exact>
                <p
                  style={{
                    fontFamily: "PlusJSMedium",
                    fontWeight: "500",
                    marginTop: -8,
                    fontSize: 15,
                    marginLeft: "5.5%",
                    color: "black",
                    color: "black",
                    opacity: 0.6,
                  }}
                >
                  Contact us
                </p>
              </a>
            </MDBCol>

            <MDBCol style={{ marginTop: 40, paddingLeft: "10%" }} size="12">
              <p
                style={{
                  display: "inline-block",
                  color: "black",
                  fontFamily: "PlusJSMedium",
                  fontWeight: "500",
                  fontSize: 15,
                  opacity: 0.8,
                }}
              >
                © 2024 ActorShield. All rights reserved.
              </p>
            </MDBCol>
          </MDBRow>
        </div>
      </>
    );
  }
}

export default MobileFooter;
