import React from 'react';
import MenuDesktop from './MenuDesktop';
import '../../Page/Dashboard/index.css';
import { MDBRow, MDBCol } from 'mdbreact';

const mobileLayout = props => {
    return (
        <>
            <MDBRow style={{ width: '100vw', overFlow: 'hidden' }}>
                <MDBCol size='12'>
                    <div>{props.children}</div>
                </MDBCol>
            </MDBRow>
        </>
    );
};

export default mobileLayout;
