import React, { Component } from "react";
import "./index.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Login from './Components/Page/Login/Login';
// import SignUp from './Components/Page/SignUp/SignUp';
import BasePage from "./Components/Page/BasePage";
import Layout from "./Components/Parts/Layout/layout";

// import ForgetPassword from './Components/Page/ForgetPassword/ForgetPassword';
import MobileLayout from "./Components/Parts/Layout/mobileLayout";
import mixpanel from "mixpanel-browser";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init("096e3f7912ba91fcc6db94f62f679c49", { debug: true });

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      loading: true,
      subscribed: false,
      status: "",
      accountVisible: false,
      isDesktop: null,
    };

    this.setLoading = this.setLoading.bind(this);
    this.setUser = this.setUser.bind(this);
  }
  hideAccountVisibility = () => {
    this.setState({ accountVisible: false });
  };
  componentDidMount() {
    this.screenSizeDetector();
  }

  setLoading = (e) => this.setState({ loading: e });

  setUser = (e) => this.setState({ user: e });

  screenSizeDetector = () => {
    this.setState({ isDesktop: window.innerWidth > 1000 });
  };
  render() {
    // if (this.state.loading || this.state.isDesktop === null) {
    //     return <></>;
    // }

    return (
      <Router>
        {
          <Switch>
            {this.state.isDesktop ? (
              <>
                <Route
                  render={(props) => (
                    <Layout
                      subscribed={this.state.subscribed}
                      hideAccountVisibility={this.hideAccountVisibility}
                      status={this.state.status}
                    >
                      <BasePage
                        hideAccountVisibility={this.hideAccountVisibility}
                        subscribed={this.state.subscribed}
                        status={this.state.status}
                        subdomain={this.state.subdomain}
                        // user={this.state.user}

                        clinicId={this.state.clinicId}
                        clinicName={this.state.clinicName}
                        user={this.state.user}
                      />
                    </Layout>
                  )}
                />
              </>
            ) : (
              <>
                <Route
                  render={(props) => (
                    <MobileLayout
                      hideAccountVisibility={this.hideAccountVisibility}
                      status={this.state.status}
                    >
                      <BasePage
                        hideAccountVisibility={this.hideAccountVisibility}
                        subscribed={this.state.subscribed}
                        subdomain={this.state.subdomain}
                        status={this.state.status}
                        user={this.state.user}
                        clinicId={this.state.clinicId}
                        clinicName={this.state.clinicName}
                      />
                    </MobileLayout>
                  )}
                />
              </>
            )}
          </Switch>
        }
      </Router>
    );
  }
}

export default App;
