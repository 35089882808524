import React from 'react';
import MenuDesktop from './MenuDesktop';
import '../../Page/Dashboard/index.css';

const Layout = props => {
    return (
        <div>
            <div
                style={{
                    backgroundColor: 'white',


                }}

            >
                {props.children}
            </div>
        </div>
    );
};

export default Layout;
