import React, { Component } from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import Landing from "../Parts/Landing/Landing";
import LandingMobile from "../Parts/Landing/LandingMobile";
import About from "../Parts/About/About";
import AboutMobile from "../Parts/About/AboutMobile";
import Pricing from "../Parts/Pricing/Pricing";
import PricingMobile from "../Parts/Pricing/PricingMobile";
import Hiring from "../Parts/Hiring/Hiring";
import HiringMobile from "../Parts/Hiring/HiringMobile";
import Job from "../Parts/Job/Job";
import JobMobile from "../Parts/Job/JobMobile";
import ScrollToTop from "./ScrollToTop";
import { withRouter } from "react-router-dom";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

class BasePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentUserID: "",
      isDesktop: 0,
      subscribed: false,
      clinicId: "",
      clinicName: "",
      version: "",
      subdomain: "",
      upgraded: false,
    };
    // this.getCurrentUserInfo = this.getCurrentUserInfo.bind(this);
    this.screenSizeDetector = this.screenSizeDetector.bind(this);
    this.setCurrentUserID = this.setCurrentUserID.bind(this);
  }

  componentDidMount() {
    if (
      window.location.host.includes("localhost") ||
      window.location.host.includes("ngrok")
    ) {
      this.setState({ clinicName: "Lobby Dental Clinic" });
      this.setState({ subdomain: "orange" });
    } else {
      var subdomain = window.location.host.split(".")[0];
      this.setState({ subdomain: subdomain });
      this.setState({
        clinicName:
          subdomain === "whitevibes"
            ? "White Vibes Dental Clinic"
            : capitalizeFirstLetter(subdomain) + " " + "Dental Clinic",
      });
    }

    this.getCurrentUserInfo();
    this.screenSizeDetector();

    window.addEventListener("resize", this.screenSizeDetector);
  }

  screenSizeDetector = () => {
    if (window.innerWidth > 900 && window.innerWidth < 1000) {
      this.setState({ version: "Tablet", isDesktop: true });
    } else if (window.innerWidth <= 900) {
      this.setState({ version: "Mobile", isDesktop: false });
    } else {
      this.setState({ version: "Desktop", isDesktop: true });
    }
  };

  setCurrentUserID = (userID) => {
    this.setState({ CurrentUserID: userID });
  };

  // //Get current user info
  getCurrentUserInfo = () => {};

  componentDidUpdate(prevProps, prevState) {
    // get the user every time the route chages

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getCurrentUserInfo();
    }
  }

  render() {
    return (
      <>
        <ScrollToTop />

        <Switch>
          <Route
            exact
            path="/careers/job"
            render={() =>
              this.state.isDesktop ? (
                <Job
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  version={this.state.version}
                  subdomain={this.state.subdomain}
                />
              ) : (
                <JobMobile
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  subdomain={this.state.subdomain}
                />
              )
            }
          />

          <Route
            exact
            path="/careers"
            render={() =>
              this.state.isDesktop ? (
                <Hiring
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  version={this.state.version}
                  subdomain={this.state.subdomain}
                />
              ) : (
                <HiringMobile
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  subdomain={this.state.subdomain}
                />
              )
            }
          />

          <Route
            exact
            path="/about"
            render={() =>
              this.state.isDesktop ? (
                <About
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  version={this.state.version}
                  subdomain={this.state.subdomain}
                />
              ) : (
                <AboutMobile
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  subdomain={this.state.subdomain}
                />
              )
            }
          />

          <Route
            exact
            path="/sign-in"
            render={() =>
              this.state.isDesktop ? (
                <SignIn
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  version={this.state.version}
                  subdomain={this.state.subdomain}
                />
              ) : (
                <SignInMobile
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  subdomain={this.state.subdomain}
                />
              )
            }
          />

          <Route
            exact
            path="/pricing"
            render={() =>
              this.state.isDesktop ? (
                <Pricing
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  version={this.state.version}
                  subdomain={this.state.subdomain}
                />
              ) : (
                <PricingMobile
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  subdomain={this.state.subdomain}
                />
              )
            }
          />

          <Route
            exact
            path="/"
            render={() =>
              this.state.isDesktop ? (
                <Landing
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  version={this.state.version}
                  subdomain={this.state.subdomain}
                />
              ) : (
                <LandingMobile
                  status={this.props.status}
                  CurrentUser={this.state.CurrentUserID}
                  hideAccountVisibility={this.props.hideAccountVisibility}
                  links={this.state.links}
                  subscribed={this.state.subscribed}
                  clinicId={this.props.clinicId}
                  clinicName={this.props.clinicName}
                  subdomain={this.state.subdomain}
                />
              )
            }
          />

          <Redirect to="/" />
        </Switch>
      </>
    );
  }
}

export default withRouter(BasePage);
