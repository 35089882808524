import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import { MDBRow, MDBCol } from "mdbreact";
import Collapsible from "react-collapsible";
import { NavLink, withRouter } from "react-router-dom";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { BiGridSmall } from "react-icons/bi";
import mixpanel from "mixpanel-browser";

import "./index.css";
import { SkyLightStateless } from "react-skylight";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import { GoPrimitiveDot } from "react-icons/go";
import ReactStars from "react-stars";
import { TiTick } from "react-icons/ti";

import MobileFooter from "../Layout/mobileFooter";

import FAQMobile from "../FAQMobile";

import MobileTopMenu from "../Layout/mobileTopMenu";

class PricingMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      querySellers: [],
    };
  }

  render() {
    return (
      <>
        <div
          style={{
            width: "100vw",
            backgroundColor: "black",
            color: "white",
            overflowX: "hidden",
          }}
        >
          <MDBRow style={{ marginTop: 0, paddingBottom: 0 }}>
            <MobileTopMenu color="dark" />
            <MDBRow>
              <MDBCol size="12">
                <MDBRow style={{ height: "50vh" }}>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "#175fff",
                        fontSize: 16,
                        marginTop: 90,
                        textAlign: "center",
                        opacity: 1,
                      }}
                    >
                      OUR MODEL
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSExtraBold",
                        fontSize: 30,
                        marginTop: -5,
                        lineHeight: 1.3,
                        width: "90%",
                        textAlign: "center",
                      }}
                    >
                      No Win. No Fee.
                      <br />
                      Risk-free brand protection.
                    </p>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "white",
                        opacity: 0.7,
                        fontSize: 17,
                        width: "85%",
                        marginTop: 0,
                        textAlign: "center",
                        opacity: 0.7,
                      }}
                    >
                      We work hard to discover and win cases of AI generated
                      testimonials using your likeness. And we take a cut of
                      what we earn you. We never charge you upfont.
                    </p>
                  </MDBCol>

                  <MDBCol className="d-flex justify-content-center" size="12">
                    <a
                      href="mailto:contact@actorshield.com"
                      target="_blank"
                      className="d-flex justify-content-center"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          backgroundColor: "#175fff",
                          borderRadius: 5,
                          paddingTop: 10,
                          color: "white",
                          fontFamily: "PlusJSBold",
                          fontSize: 17,
                          width: 170,
                          height: 49,
                          marginTop: 10,
                          display: "inline-block",
                        }}
                      >
                        Get in touch
                      </div>
                    </a>
                  </MDBCol>
                  <MDBCol className="d-flex justify-content-center" size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "white",
                        fontSize: 12,
                        marginTop: 5,
                        textAlign: "center",
                        opacity: 1,
                      }}
                    >
                      Have questions?
                      <a
                        href="mailto:contact@actorshield.com"
                        style={{
                          display: "inline-block",
                          color: "#175fff",
                          textDecoration: "underline",
                        }}
                      >
                        Contact us
                      </a>
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol
                className="d-flex justify-content-center"
                size="12"
              ></MDBCol>
            </MDBRow>
          </MDBRow>

          <MDBRow
            style={{
              backgroundColor: "white",
              marginTop: 150,
              overflowY: "hidden",
              paddingTop: 0,
              paddingBottom: 50,
              color: "#1c1825",
              width: "105vw",
              overflowX: "hidden",
            }}
          >
            <MDBCol size="12">
              <p
                style={{
                  fontFamily: "PlusJSExtraBold",
                  fontSize: 29,
                  marginTop: 50,
                  lineHeight: 1.3,
                  width: "100%",
                  marginLeft: "6%",
                }}
              >
                Two stage process
              </p>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  color: "black",
                  opacity: 0.7,
                  fontSize: 17,
                  width: "90%",
                  marginTop: 0,
                  opacity: 0.7,
                  marginLeft: "6%",
                }}
              >
                At first we try to negotiate a fair license fee for your
                endorsement rights, this is the quickest path for both parties.
                If this fails we pursue further action to make sure your rights
                are protected.
              </p>
            </MDBCol>
            <MDBCol size="12">
              <div
                style={{
                  marginTop: 25,
                  width: "90%",
                  height: 200,
                  marginLeft: "6%",
                  backgroundColor: "white",
                  boxShadow: "0 0 40px 0 rgb(27 6 76 / 10%)",
                }}
              >
                <MDBRow>
                  <MDBCol size="2">
                    <img
                      src={require("./startups.png")}
                      style={{
                        height: 35,
                        width: "auto",
                        marginTop: 40,
                        marginLeft: 40,
                      }}
                    />
                  </MDBCol>
                  <MDBCol size="10">
                    <p
                      style={{
                        fontFamily: "PlusJSExtraBold",
                        fontSize: 19,
                        marginTop: 48,
                        lineHeight: 1.3,
                        width: "100%",
                        marginLeft: 30,
                      }}
                    >
                      Negotiate License
                    </p>
                  </MDBCol>
                  <MDBCol size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 15,
                        width: "80%",
                        marginTop: 5,
                        opacity: 0.9,
                        marginLeft: 40,
                      }}
                    >
                      We try our best to negotiate a fair license for the
                      endorsement, we hope this process is fast for both
                      parties.
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
            <MDBCol size="12">
              <div
                style={{
                  marginTop: 25,
                  width: "90%",
                  marginLeft: "6%",
                  height: 200,
                  backgroundColor: "white",
                  boxShadow: "0 0 40px 0 rgb(27 6 76 / 10%)",
                }}
              >
                <MDBRow>
                  <MDBCol size="2">
                    <img
                      src={require("./enterprise.png")}
                      style={{
                        height: 35,
                        width: "auto",
                        marginTop: 40,
                        marginLeft: 40,
                      }}
                    />
                  </MDBCol>
                  <MDBCol size="10">
                    <p
                      style={{
                        fontFamily: "PlusJSExtraBold",
                        fontSize: 19,
                        marginTop: 48,
                        lineHeight: 1.3,
                        width: "100%",
                        marginLeft: 30,
                      }}
                    >
                      Lawsuit
                    </p>
                  </MDBCol>
                  <MDBCol size="12">
                    <p
                      style={{
                        fontFamily: "PlusJSMedium",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 15,
                        width: "80%",
                        marginTop: 5,
                        opacity: 0.9,
                        marginLeft: 40,
                      }}
                    >
                      If we are unable to successfuly settle the case, we work
                      with our legal partners to sue the advertiser to protect
                      your rights.
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <div
              className="pattern2"
              style={{
                height: 400,
                width: "105vw",
                backgroundRepeat: "repeat",
              }}
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 29,
                      color: "black",
                      marginTop: 100,
                      lineHeight: 1.3,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Ready to protect yourself?
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 16,
                      width: "85%",
                      marginTop: 0,
                      textAlign: "center",
                      opacity: 0.7,
                    }}
                  >
                    If you have any questions about our process or how we work
                    please get in touch with our team.
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <a
                    href="mailto:contact@actorshield.com"
                    target="_blank"
                    className="d-flex justify-content-center"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: "#175fff",
                        borderRadius: 5,
                        paddingTop: 10,
                        color: "white",
                        fontFamily: "PlusJSBold",
                        fontSize: 17,
                        width: 170,
                        height: 49,
                        marginTop: 10,
                        display: "inline-block",
                      }}
                    >
                      Get in touch
                    </div>
                  </a>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      fontSize: 12,
                      marginTop: 5,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    Have questions?{" "}
                    <a
                      href="mailto:contact@actorshield.com"
                      style={{
                        display: "inline-block",
                        color: "#175fff",
                        textDecoration: "underline",
                      }}
                    >
                      Contact us
                    </a>
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBRow>
        </div>
        <MobileFooter
          executeScroll1={this.executeScroll1}
          executeScroll2={this.executeScroll2}
          executeScroll3={this.executeScroll3}
          executeScroll4={this.executeScroll4}
        />
      </>
    );
  }
}

export default withRouter(PricingMobile);
