import React, { Component } from 'react';
import '../../Page/Dashboard/index.css';
import "../index.css"
import TopMenu from '../Layout/TopMenu';
import Collapsible from 'react-collapsible';
import { MDBRow, MDBCol } from 'mdbreact';
import { IoSearch } from "react-icons/io5"
import { NavLink } from 'react-router-dom';






class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }




    render() {
        return (
            <>
                <div className='d-flex justify-content-between' style={{ height: 50, position: "fixed", width: "100%", zIndex: 3000, paddingTop: this.props.version === "Tablet" ? 16 : 14, backgroundColor: "#F0F0F0" }}>
                    <p style={{ color: "black", fontFamily: "EinaSemiBold", fontSize: 17, marginLeft: 60 }}>Get your new smile designed at a local Lobby today. <p className='d-inline' style={{ fontFamily: "EinaBold" }}>Book your spot now.</p></p>

                    <p style={{ color: "black", fontFamily: "EinaSemiBold", fontSize: 17, marginRight: 30 }}>Call us at: +44 7507435922</p>
                </div>
                <NavLink
                    to="/signout"
                    exact
                    onClick={() => window.scrollTo(0, 0)}>
                    <div style={{ cursor: "pointer", width: "100%", position: "fixed", height: 51, zIndex: 3000, top: 50, borderBottom: "1.4px solid #dedddd", backgroundColor: "white" }}>
                        <p style={{ color: "black", fontFamily: "EinaBold", fontSize: this.props.version === "Tablet" ? 19 : 21, marginTop: 11, marginLeft: 60 }}>Find your local Smile Lobby</p>
                    </div>
                </NavLink>
            </>


        );
    }
}

export default NavBar;
