import React, { Component } from "react";
import "../../Page/Dashboard/index.css";
import "../index.css";
import "./index.css";
import TopMenu from "../Layout/TopMenu";
import Collapsible from "react-collapsible";
import MenuDesktop from "../Layout/MenuDesktop";
import { MDBRow, MDBCol } from "mdbreact";
import { IoSearch } from "react-icons/io5";
import { NavLink, withRouter } from "react-router-dom";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import NavBar from "../Layout/NavBar";
import mixpanel from "mixpanel-browser";
import { HiLightningBolt, HiSortAscending } from "react-icons/hi";
import ReactStars from "react-stars";

import { SkyLightStateless } from "react-skylight";
import { TiTick } from "react-icons/ti";
import VisibilitySensor from "react-visibility-sensor";
import Scroll from "react-scroll";
import Footer from "../Layout/Footer";

class Job extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overview: "",
      a: "",
      b: "",
      c: "",
      d: "",
      e: "",
      f: "",
      title: "",
      category: "",
      line1: "",
      line2: "",
      line3: "",
      line4: "",
    };
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  openModal = () => {
    this.setState({ signUp: true, isNavbar: false });
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const overview = params.get("overview");
    const category = params.get("category");
    const title = params.get("title");
    const line1 = params.get("line1");
    const line2 = params.get("line2");
    const line3 = params.get("line3");
    const line4 = params.get("line4");
    const a = params.get("a");
    const b = params.get("b");
    const c = params.get("c");
    const d = params.get("d");
    const e = params.get("e");
    const f = params.get("f");
    this.setState({
      overview,
      a,
      b,
      c,
      d,
      e,
      f,
      title,
      category,
      line1,
      line2,
      line3,
      line4,
    });
  }

  render() {
    console.log(this.props.version);
    return (
      <>
        <MDBCol
          className="backy26"
          style={{
            width: "100vw",
            overflowX: "hidden",
            backgroundColor: "white",
            color: "black",
          }}
          size="12"
        >
          <MDBRow>
            <MenuDesktop color="light" />
            <MDBCol size="12">
              <MDBRow style={{ paddingBottom: "5vh", paddingLeft: "10%" }}>
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSBold",
                      color: "black",
                      fontSize: 14,
                      marginTop: 90,
                      opacity: 0.7,
                    }}
                  >
                    {this.state.category.toUpperCase()}
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 35,
                      marginTop: -5,
                      lineHeight: 1.3,
                      width: "100%",
                    }}
                  >
                    {this.state.title}
                  </p>
                </MDBCol>
                <MDBCol size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 16,
                      width: "50%",
                      marginTop: 0,
                      opacity: 0.7,
                    }}
                  >
                    <img
                      src={require("./remote.png")}
                      style={{
                        height: 20,
                        width: "auto",
                        marginRight: 10,
                        marginTop: -4,
                      }}
                    />
                    Remote | Full-time
                  </p>
                </MDBCol>

                <MDBCol size="4">
                  <a
                    href="mailto:apply@actorshield.com"
                    target="_blank"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div
                      className="d-flex justfy-content-between"
                      style={{
                        backgroundColor: "black",
                        borderRadius: 0,
                        paddingTop: 10,
                        color: "white",
                        fontFamily: "PlusJSBold",
                        fontSize: 15,
                        width: 220,
                        height: 49,
                        marginTop: 70,
                        display: "inline-block",
                      }}
                    >
                      <p style={{ marginTop: 4, marginLeft: 17 }}>Apply</p>
                      <RiArrowRightSLine
                        style={{
                          color: "white",
                          marginLeft: 120,
                          marginTop: 6,
                          fontSize: 17,
                        }}
                      />
                    </div>
                  </a>
                  <div
                    style={{ width: 220, borderBottom: "1px solid #cbcbcb" }}
                  >
                    <p
                      style={{
                        fontFamily: "PlusJSBold",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 12,
                        width: "50%",
                        marginTop: 50,
                        opacity: 0.3,
                        letterSpacing: 1.5,
                      }}
                    >
                      REMOTE
                    </p>
                  </div>

                  <div
                    style={{ width: 220, borderBottom: "1px solid #cbcbcb" }}
                  >
                    <p
                      style={{
                        fontFamily: "PlusJSBold",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 12,
                        width: "50%",
                        marginTop: 30,
                        opacity: 0.3,
                        letterSpacing: 1.5,
                      }}
                    >
                      FULL-TIME
                    </p>
                  </div>
                  <div
                    style={{ width: 220, borderBottom: "1px solid #cbcbcb" }}
                  >
                    <p
                      style={{
                        fontFamily: "PlusJSBold",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 12,
                        marginTop: 30,
                        opacity: 0.3,
                        letterSpacing: 1.5,
                      }}
                    >
                      {this.state.category.toUpperCase()}
                    </p>
                  </div>
                </MDBCol>
                <MDBCol size="8">
                  <p
                    style={{
                      fontFamily: "PlusJSRegular",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 15,
                      marginTop: 70,
                      opacity: 0.8,
                      marginLeft: -90,
                      width: "87%",
                    }}
                  >
                    {this.state.line1}
                    <br />
                    <br />
                    {this.state.line2}
                    <br />
                    <br />
                    {this.state.line3}
                    <br />
                    <br />
                    {this.state.line4}
                  </p>

                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 25,
                      marginTop: 40,
                      opacity: 0.8,
                      marginLeft: -90,
                      width: "87%",
                    }}
                  >
                    What excites you
                  </p>
                  <ul>
                    <li
                      style={{
                        fontFamily: "PlusJSRegular",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 15,
                        marginTop: 15,
                        opacity: 0.8,
                        marginLeft: -90,
                        width: "87%",
                      }}
                    >
                      {this.state.a}
                    </li>
                    <li
                      style={{
                        fontFamily: "PlusJSRegular",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 15,
                        marginTop: 10,
                        opacity: 0.8,
                        marginLeft: -90,
                        width: "87%",
                      }}
                    >
                      {this.state.b}
                    </li>
                    <li
                      style={{
                        fontFamily: "PlusJSRegular",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 15,
                        marginTop: 10,
                        opacity: 0.8,
                        marginLeft: -90,
                        width: "87%",
                      }}
                    >
                      {this.state.c}
                    </li>
                  </ul>

                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 25,
                      marginTop: 40,
                      opacity: 0.8,
                      marginLeft: -90,
                      width: "87%",
                    }}
                  >
                    What excites us
                  </p>
                  <ul>
                    <li
                      style={{
                        fontFamily: "PlusJSRegular",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 15,
                        marginTop: 15,
                        opacity: 0.8,
                        marginLeft: -90,
                        width: "87%",
                      }}
                    >
                      {this.state.d}
                    </li>
                    <li
                      style={{
                        fontFamily: "PlusJSRegular",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 15,
                        marginTop: 10,
                        opacity: 0.8,
                        marginLeft: -90,
                        width: "87%",
                      }}
                    >
                      {this.state.e}
                    </li>
                    <li
                      style={{
                        fontFamily: "PlusJSRegular",
                        color: "black",
                        opacity: 0.7,
                        fontSize: 15,
                        marginTop: 10,
                        opacity: 0.8,
                        marginLeft: -90,
                        width: "87%",
                      }}
                    >
                      {this.state.f}
                    </li>
                  </ul>

                  <p
                    style={{
                      fontFamily: "PlusJSRegular",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 15,
                      marginTop: 40,
                      opacity: 0.8,
                      marginLeft: -90,
                      width: "87%",
                    }}
                  >
                    ActorShield is proud to be an equal opportunity employer and
                    values diversity at our company. We do not discriminate
                    based on race, color, national origin, ethnicity, religion
                    or religious belief, sex (including pregnancy, childbirth,
                    or related medical conditions), sexual orientation, gender,
                    gender identity, gender expression, transgender status,
                    sexual stereotypes, age, military or veteran status,
                    disability, or other applicable legally protected
                    characteristics. We also consider qualified applicants with
                    criminal histories, consistent with applicable federal,
                    state, and local laws. ActorShield is committed to providing
                    reasonable accommodations for candidates with disabilities
                    in our recruiting process. If you need any assistance with
                    your application or interviews due to a disability, please
                    let us know at contact@actorshield.com
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <div
              className="pattern2"
              style={{
                height: 400,
                width: "100vw",
                backgroundRepeat: "repeat",
              }}
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSExtraBold",
                      fontSize: 29,
                      color: "black",
                      marginTop: 100,
                      lineHeight: 1.3,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Excited to join our mission?
                  </p>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      opacity: 0.7,
                      fontSize: 16,
                      width: "30%",
                      marginTop: 0,
                      textAlign: "center",
                      opacity: 0.7,
                    }}
                  >
                    We're assembling a team of wonderful people that love
                    solving hard problems and working together.
                  </p>
                </MDBCol>

                <MDBCol className="d-flex justify-content-center" size="12">
                  <NavLink
                    to="/about"
                    exact
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: "#175fff",
                        borderRadius: 5,
                        paddingTop: 10,
                        color: "white",
                        fontFamily: "PlusJSBold",
                        fontSize: 17,
                        width: 170,
                        height: 49,
                        marginTop: 10,
                        display: "inline-block",
                      }}
                    >
                      Read more
                    </div>
                  </NavLink>
                </MDBCol>
                <MDBCol className="d-flex justify-content-center" size="12">
                  <p
                    style={{
                      fontFamily: "PlusJSMedium",
                      color: "black",
                      fontSize: 12,
                      marginTop: 5,
                      textAlign: "center",
                      opacity: 1,
                    }}
                  >
                    Have questions?{" "}
                    <a
                      href="mailto:contact@actorshield.com"
                      style={{
                        display: "inline-block",
                        color: "#175fff",
                        textDecoration: "underline",
                      }}
                    >
                      Contact us
                    </a>
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBRow>
        </MDBCol>

        <Footer />
      </>
    );
  }
}

export default withRouter(Job);
