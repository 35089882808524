import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBAnimation,
  MDBIcon,
  MDBTooltip,
} from "mdbreact";

import "../index.css";
import "./index.css";
import { NavLink } from "react-router-dom";
import {
  RiHomeSmile2Line,
  RiApps2Line,
  RiStore2Line,
  RiMoneyDollarBoxLine,
  RiSettings4Line,
  RiLogoutBoxLine,
  RiKey2Line,
  RiKeyFill,
  RiKey2Fill,
  RiTestTubeFill,
} from "react-icons/ri";
import { HiCash } from "react-icons/hi";
import { BiSearch, BiCart } from "react-icons/bi";
import { IoRadioSharp } from "react-icons/io5";
import { FaQuestion, FaTeethOpen } from "react-icons/fa";
class MenuDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "Settings",
      isNavbar: true,
    };
  }

  render() {
    return (
      <>
        <MDBCol
          className="d-flex justify-content-between"
          style={{
            backgroundColor: this.props.color === "dark" ? "black" : "white",
            height: 86,
          }}
          size="12"
        >
          <NavLink to="/" exact onClick={() => window.scrollTo(0, 0)}>
            <p
              style={{
                fontFamily: "PlusJSBold",
                color: this.props.color === "dark" ? "white" : "black",
                fontSize: 23,
                fontWeight: "900",
                marginTop: 32,
                display: "inline-block",
                marginLeft: 85,
              }}
            >
              <img
                style={{
                  height: 35,
                  width: "auto",
                  marginRight: 5,
                  marginTop: -5,
                  transform: "rotate(45deg)",
                }}
                src={require("./logo.png")}
              />
              ActorShield
            </p>
          </NavLink>

          <div style={{ display: "inline-block" }}>
            <NavLink to="/pricing" exact onClick={() => window.scrollTo(0, 0)}>
              {" "}
              <p
                style={{
                  cursor: "pointer",
                  fontFamily: "PlusJSMedium",
                  fontSize: 15,
                  marginTop: 38,
                  display: "inline-block",
                  marginLeft: 23,
                  marginRight: 23,
                  color: this.props.color === "dark" ? "white" : "black",
                }}
              >
                Our Model
              </p>
            </NavLink>
            <NavLink to="/about" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  color: this.props.color === "dark" ? "white" : "black",
                  cursor: "pointer",
                  fontFamily: "PlusJSMedium",
                  fontSize: 15,
                  marginTop: 38,
                  display: "inline-block",
                  marginLeft: 23,
                  marginRight: 23,
                }}
              >
                About us
              </p>
            </NavLink>
            <NavLink to="/careers" exact onClick={() => window.scrollTo(0, 0)}>
              <p
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 15,
                  marginTop: 38,
                  display: "inline-block",
                  marginLeft: 23,
                  marginRight: 23,
                  cursor: "pointer",
                  color: this.props.color === "dark" ? "white" : "black",
                }}
              >
                We're hiring!
              </p>
            </NavLink>
            <a
              href="mailto:contact@actorshield.com"
              style={{ marginTop: -20 }}
              exact
              onClick={() => window.scrollTo(0, 0)}
            >
              <div
                style={{
                  fontFamily: "PlusJSMedium",
                  fontSize: 15,
                  marginTop: -24,
                  borderRadius: 5,
                  display: "inline-block",
                  marginLeft: 23,
                  marginRight: 23,
                  color: "white",
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 37,
                  width: 120,
                  backgroundColor: "#175fff",
                }}
              >
                <p style={{ marginTop: 8, textAlign: "center" }}>
                  Get in touch
                </p>
              </div>
            </a>
          </div>
        </MDBCol>
      </>
    );
  }
}

export default MenuDesktop;
